@import 'theme';

footer {
  min-height: 56px;
  width: 100%;
  display: flex;
  top: 0;
  padding: 16px;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 9;
  box-sizing: border-box;

  .footer-inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    max-width: 1152px;
  }

  ul {
    margin: 0;
    margin-block: 0;
    padding-inline: 0;
  }
}