@import 'theme';

header {
  height: 56px;
  width: 100%;
  position: relative;
  display: flex;
  top: 0;
  padding: 16px;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 9;
  background-color: $surface-color-fully-transparent;
  transition: all 200ms ease-in-out;

  .header-inner {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1152px;

    img {
      vertical-align: middle;
    }
  }

  ul {
    margin: 0;
    margin-block: 0;
    padding-inline: 0;
  }

  &:not(.scrolled) {
    .button {
      @extend .white
    }
  }

  &.surface-on-scroll.scrolled {
    background-color: $surface-color;
    color: $text-color-high-emphasis;
    box-shadow: $elevation-4;
  }

  .tagline {
    margin-left: 16px;
  }

  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 72px;
    padding-right: 72px;
  }
}