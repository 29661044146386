$primary-color-dark: #092C44;
$primary-color: #197BBD;
$primary-color-lighter: #54A7DE;
$primary-color-light: #E1EDF5;
$secondary-color: #00A693;
$secondary-color-lighter: #1dc3b1;
$tertiary-color-darker: #D13E3B;
$tertiary-color: #F25F5C;
$tertiary-color-lighter: #FF8784;
$light-grey: #F0F0F0;
$grey-on-background: #EBEBEB;
$grey-on-background-darker: #DEDEDE;
$surface-color: #FAFAFA;
$surface-color-fully-transparent: rgba(250, 250, 250, 0);
$background-color: #F5F5F5;

$text-color-high-emphasis: rgba(0, 0, 0, 0.87);
$text-color-medium-emphasis: rgba(0, 0, 0, 0.6);
$text-color-low-emphasis: rgba(0, 0, 0, 0.4);

$font-body: -apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif;
$font-header: "Helvetica Neue", "Open Sans", "Helvetica", sans-serif;

$elevation-2: 0px 1px 5px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 3px 1px -2px rgba(0, 0, 0, 0.06);
$elevation-4: 0px 2px 4px -1px rgba(0,0,0,0.06),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.04);
$elevation-24: 0px 11px 15px -7px rgba(0,0,0,0.06),0px 24px 38px 3px rgba(0,0,0,0.04),0px 9px 46px 8px rgba(0,0,0,0.04);

$breakpoint-tablet: 480;

html {
  scroll-behavior: smooth;
}

html, body {
  background-color: $background-color;
  font-family: $font-body;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: $text-color-high-emphasis;

  div, section, header {
    box-sizing: border-box;
  }
}

.headline2 {
  margin: 0;
  font-family: $font-header;
  font-size: 3.75rem;
  font-weight: bold;
  letter-spacing: -0.0083rem;
  line-height: 4rem;
}
.headline3 {
  margin: 0;
  font-family: $font-header;
  font-size: 3rem;
  font-weight: bold;
}
.headline4 {
  font-family: $font-header;
  font-size: 2.125rem;
  font-weight: bold;
  letter-spacing: 0.007rem;
}
.headline5 {
  margin: 0;
  font-family: $font-header;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0;
}
.headline6 {
  font-family: $font-header;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.008rem;
}
.subtitle1 {
  margin: 0;
  font-family: $font-header;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.001rem;
}
.subtitle2 {
  margin: 0;
  font-family: $font-header;
  font-size: 0.875rem;
  font-weight: 450;
  letter-spacing: 0.007rem;
}
.body-large {
  margin: 0;
  font-family: $font-body;
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: 0.03rem;
}
.body1 {
  margin: 0;
  font-family: $font-body;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.03rem;
  line-height: 1.4rem;
}
.body2 {
  margin: 0;
  font-family: $font-body;
  font-size: 0.875rem;
  font-weight: normal;
  letter-spacing: 0.018rem;
  line-height: 1.3rem;
}
.caption {
  margin: 0;
  font-family: $font-body;
  font-size: 0.8125rem;
  font-weight: normal;
  letter-spacing: 0.03rem;
}
.button-text {
  font-family: $font-body;
  font-size: 0.875rem;
  font-weight: 450;
  letter-spacing: 0.089rem;
  text-decoration: none;
  color: $text-color-high-emphasis;

  &:hover {
    text-decoration: underline;
  }

  &.primary {
    color: $primary-color;
  }
}
.overline {
  font-family: $font-body;
  font-size: 0.625rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.overline-large {
  font-family: $font-body;
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

.medium-emphasis {
  color: $text-color-medium-emphasis;
}

.button {
  @extend .button-text;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 1000px;
  transition: all 100ms ease-in-out;
  box-shadow: $elevation-4;
  text-decoration: none !important;
  min-height: 24px;
  border: none;

  &:active, &:focus {
    outline: none;
    border: none;
  }

  &:disabled {
    background-color: #CCCCCC !important;
    cursor: unset;
  }

  &.small {
    padding: 12px 16px;
    min-height: unset;
  }

  &.no-elevation {
    box-shadow: none;
  }

  &.primary {
    background-color: $primary-color;
    color: white;

    &:hover {
      background-color: $primary-color-lighter;
    }
  }

  &.secondary {
    background-color: $secondary-color;
    color: white;

    &:hover {
      background-color: $secondary-color-lighter;
    }
  }

  &.tertiary {
    background-color: $tertiary-color;
    color: white;

    &:hover {
      background-color: $tertiary-color-lighter;
    }
  }

  &.white {
    background-color: #FFFFFF;
    color: $text-color-high-emphasis;
    box-shadow: $elevation-2;

    &:hover {
      background-color: $light-grey;
    }
  }

  &.app-store {
    background-color: #000000;
    display: flex;
    color: white;
    padding: 10px 16px;

    &:hover {
      background-color: #333333;
    }

    .inner-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;

      .inner-text-top {
        font-size: .5rem;
        font-weight: normal;
      }

      .inner-text-bottom {
        font-size: .875rem;
      }
    }
  }

  &.resting {
    box-shadow: none;
    background: $grey-on-background;
    color: $text-color-high-emphasis;

    &:hover {
      background-color: $grey-on-background-darker;
    }
  }

  &.square {
    border-radius: 8px;
  }

  &.switch {
    pointer-events: none;

    &:not(.active) {
      pointer-events: unset;
      background: none;
      box-shadow: none;
      color: $text-color-high-emphasis;

      &:hover {
        background: $grey-on-background-darker;
      }
    }
  }
}

span {
  &.tertiary {
    color: $tertiary-color;
  }
  &.tertiary-darker {
    color: $tertiary-color-darker;
  }
}

a {
  color: $primary-color;
}