@-webkit-keyframes orbit {
  0% { -webkit-transform: rotate(0deg) translateX(200px) rotate(0deg); }
  100%   { -webkit-transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@-moz-keyframes orbit {
  from { -moz-transform: rotate(0deg) translateX(200px) rotate(0deg); }
  to   { -moz-transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@-o-keyframes orbit {
  from { -o-transform: rotate(0deg) translateX(200px) rotate(0deg); }
  to   { -o-transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}

@keyframes orbit {
  0% { transform: rotate(0deg) translateX(200px) rotate(0deg); }
  25% { transform: rotate(90deg) translateX(200px) rotate(-90deg); }
  50%   { transform: rotate(180deg) translateX(200px) rotate(-180deg); }
  75% { transform: rotate(270deg) translateX(200px) rotate(-270deg); }
  100%   { transform: rotate(360deg) translateX(200px) rotate(-360deg); }
}