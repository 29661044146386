@import "theme";

.pull-right {
  margin-left: auto;
}

.message-bar {
  width: 100%;
  background-color: $primary-color-dark;
  padding: 8px;
  text-align: center;
  min-height: 40px;
  z-index: 9;
  position: relative;

  &.light {
    background-color: $primary-color-light;
    a {
      color: $text-color-high-emphasis;
    }
  }

  a {
    color: white;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.main-container {
  position: relative;
}

.text-glow {
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}

.block {
  display: block;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spread-children {
  >:not(:first-child) {
    margin-left: 16px;
  }
}

.spread-children-half {
  :not(:first-child) {
    margin-left: 8px;
  }
}

.list-item {
  display: flex;
  align-items: center;

  .list-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 24px;
  }
}

.margin-top {
  margin-top: 16px;
}

.margin-top-half {
  margin-top: 8px;
}

.margin-bottom {
  margin-bottom: 16px;
}

.margin-bottom-half {
  margin-bottom: 8px;
}

.elevated-icon {
  width: 48px;
  height: 48px;
  border-radius: 1000px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $surface-color;
  box-shadow: $elevation-4;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
  vertical-align: middle;
}

.menu-horizontal {
  list-style: none;

  li {
    display: inline-flex;
    padding: 16px 8px;
    align-items: center;
    justify-content: center;

    img {
      vertical-align: middle;
    }
  }
}

.align-middle {
  vertical-align: middle;
}

.divider {
  width: 100%;
  height: 1px;
  background: $grey-on-background-darker;
}

.padding-sides {
  padding-left: 16px;
  padding-right: 16px;
}

.no-padding {
  padding: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.lan-switcher {
  cursor: pointer;
}

.fader {
  .fader-background {
    visibility: hidden;
  }

  .image:not(.fader-background) {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 1s linear;

    &.active {
      opacity: 1
    }
  }
}

.card {
  border-radius: 8px;
  background: $surface-color;
  box-shadow: $elevation-2;
}

.margin-top-big {
  margin-top: 80px !important;
}