@import "theme";
@import "common";
@import "anim";

section {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-height: calc(100vh);

  &:first-of-type {
    margin-top: - 56px;
  }

  .container {
    width: calc(100% - 32px);
    max-width: 1152px;
    margin: 16px auto;
  }

  .button-container {
    margin-top: 24px;
  }

  .section-inner {
    width: 100%;
    padding: 72px 16px;
    display: grid;
    align-items: center;
    grid-auto-flow: dense;
    max-width: 1152px;
    grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
    grid-column-gap: 0;
    grid-row-gap: 40px;
    overflow: hidden;

    .text {
      grid-column: span 8;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.first {
        min-height: 50vh;
      }
    }

    .visual {
      grid-column: span 11;
      position: relative;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .image {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        z-index: 9;

        &.phone {
          max-width: 350px;
        }

        &.dashboard {
          max-width: 532px;
        }

        &#channels {
          max-width: 376px;
        }

        &#profile {
          max-width: 325px;
        }

        &#engage {
          max-width: 559px;
        }
      }

      .floater {
        position: absolute;
        box-shadow: $elevation-4;
        width: 56px;
        height: 56px;
        z-index: 8;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        background-color: $surface-color;
        align-items: center;
        left: calc(50% - 28px);
        top: calc(50% - 28px);
        -webkit-animation: orbit 60s linear infinite;
        -moz-animation: orbit 60s linear infinite;
        -o-animation: orbit 60s linear infinite;
        animation: orbit 60s linear infinite;

        .status-icon {
          position: absolute;
          box-shadow: $elevation-4;

          &.going {
            border-radius: 1000px;
            right: 0;
            bottom: 0;
          }

          &.maybe {
            border-radius: 12px 2px 2px 12px;
            right: 1px;
            bottom: 1px;
          }

          &.cant {
            border-radius: 2px;
            right: 1px;
            bottom: 1px;
          }
        }

        &.first {
          animation-delay: 0s;
        }

        &.second {
          animation-delay: -10s;
        }

        &.third {
          animation-delay: -20s;
        }

        &.fourth {
          animation-delay: -30s;
        }

        &.fifth {
          animation-delay: -40s;
        }

        &.sixth {
          animation-delay: -50s;
        }
      }
    }
  }

  .section-inner-mini {
    width: 100%;
    padding: 64px 16px;
    display: grid;
    align-items: center;
    grid-auto-flow: dense;
    max-width: 816px;
    grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
    grid-column-gap: 0;
    grid-row-gap: 40px;
    overflow: hidden;

    .text {
      grid-column: span 8;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        margin: 0;
      }
    }

    .visual {
      grid-column: span 8;
      position: relative;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .image {
        max-width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        z-index: 9;
      }
    }
  }
}