@import "theme";
@import "common";
@import "anim";

#send-link-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid #DEDEDE;
  position: relative;

  #select-country-code {
    @extend .body1;
    border-radius: 8px;
    box-shadow: $elevation-4;
    margin-top: 16px;
    border: none;
    padding: 14px 16px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    width: 88px;
    color: $text-color-high-emphasis;

    &:focus {
      outline: none;
    }
  }

  #input-number {
    @extend .body1;
    width: 0;
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 8px;
    margin-top: 16px;
    box-shadow: $elevation-4;
    border: none;
    padding: 14px 16px;
    color: $text-color-high-emphasis;

    &:focus {
      outline: none;
    }

    &.invalid {
      color: $tertiary-color;

      &::placeholder {
        color: $tertiary-color;
      }
    }
  }

  #cta_link {
    margin-top: 16px;
  }

  .recaptcha-text {
    @extend .caption;
    margin-top: 8px;
    color: $text-color-low-emphasis;

    a {
      color: $text-color-low-emphasis;
      text-decoration: underline;
    }
  }
}

#send-link-success {
  display: none;
  margin-top: 16px;
}